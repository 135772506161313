<template>
  <div class="ship">
    <content-block title="发货清单">
      <vxe-grid
        resizable
        autoResize
        ref="dTable"
        border="full"
        size="small"
        row-id="rowId"
        :data="dataList"
        showOverflow
        highlight-current-row
        highlight-hover-row
        highlight-current-column
        highlight-hover-column
        align="center"
        :scroll-y="{ enabled: true, mode: 'default', gt: 30 }"
        :columns="columnList"
        style="width: 100%"
        :edit-config="{ trigger: 'click', mode: 'cell' }"
        :edit-rules="setRules()"
      ></vxe-grid>
    </content-block>
    <content-block title="收货人信息">
      <el-descriptions :column="3" size="normal">
        <el-descriptions-item label="姓名">{{ info.receivingName || '--' }}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{ info.receivingPhone || '--' }}</el-descriptions-item>
        <el-descriptions-item label="地址">{{ info.detailAddress }}</el-descriptions-item>
      </el-descriptions>
    </content-block>
    <content-block title="物流信息">
      <el-form :model="logisticForm" :rules="logisticRules" inline ref="logisticForm">
        <el-form-item prop="logisticsObj">
          <span slot="label">
            <span style="color: red">*</span>
            物流公司
          </span>
          <el-select
            v-model.trim="logisticForm.logisticsObj"
            clearable
            filterable
            :popper-append-to-body="false"
            placeholder="请选择物流公司"
            value-key="id"
            remote
            reserve-keyword
            :remote-method="logisticsList"
          >
            <el-option v-for="item in logisticsOptions" :key="item.id" :label="item.name" :value="item">
              {{ item.name }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="logisticsOrderCode">
          <span slot="label">
            <span style="color: red">*</span>
            物流单号
          </span>
          <el-input v-model.trim="logisticForm.logisticsOrderCode" clearable placeholder="请输入物流单号"></el-input>
        </el-form-item>
      </el-form>
    </content-block>
    <content-block title="附件信息">
      <el-form inline>
        <el-form-item label="随货同行单">
          <upload
            v-model.trim="attchList"
            label="标准协议"
            :limit="10"
            :max-size="5"
            size="small"
            accept="image/jpeg,image/jpg,image/png,image/webp"
          />
        </el-form-item>
      </el-form>
    </content-block>
    <el-row class="content_block" type="flex" justify="center">
      <el-button @click="cancel" size="small">取消</el-button>
      <el-button type="primary" size="small" @click="save()">提交</el-button>
    </el-row>
    <el-dialog title="发货商品" :visible.sync="visible" width="80%">
      <!-- 2022年5月26日10:01:26 -->
      <!-- 需求 http://192.168.0.188/zentao/story-view-516.html-->
      <!-- UI: https://lanhuapp.com/web/#/item/project/detailDetach?pid=e29f070f-6371-4f4e-a9fe-7f5ba0321ef2&project_id=e29f070f-6371-4f4e-a9fe-7f5ba0321ef2&image_id=23b35a32-9652-4fa2-8c99-7085b17c90fa -->
      <div class="top" style="margin-bottom: 10px">
        <el-popover placement="right-start" width="360" trigger="manual" v-model="isShow">
          <div class="headerTitle">
            <span>批量录入</span>
            <el-tooltip class="item" effect="dark" placement="right">
              <div slot="content" class="tooltipContent" style="padding: 12px">
                <h4 style="margin-bottom: 10px">操作说明</h4>
                <ol>
                  <li>
                    单项输入框 录入修改信息，点击录入按钮，修改表单内对应数据。
                    <br />
                    单项输入框未录入信息，不修改对应数据；
                  </li>
                  <li>多选按钮取消勾选，当前项不可编辑</li>
                  <li>多选按钮选中项，点击清空按钮，清空选中信息，未选中信息不影响。</li>
                </ol>
              </div>
              <i class="el-icon-question" type="primary"></i>
            </el-tooltip>
          </div>
          <div class="content">
            <el-form :model="JZChoice" ref="JZChoice" class="JZCatrgoryForm content_block" :inline="true">
              <el-checkbox-group v-model="checkedChoices">
                <el-checkbox label="productionDate">
                  <el-form-item prop="productionDate" label-width="90px" label="生产日期：">
                    <el-date-picker
                      placeholder="请选择生产日期"
                      :disabled="!checkedChoices.includes('productionDate')"
                      type="date"
                      :picker-options="pickerOptions"
                      style="width: 200px"
                      value-format="yyyy-MM-dd"
                      v-model.trim="JZChoice.productionDate"
                    ></el-date-picker>
                  </el-form-item>
                </el-checkbox>
                <el-checkbox label="expiryDate">
                  <el-form-item prop="expiryDate" label-width="90px" label="失效日期：">
                    <el-date-picker
                      placeholder="请选择失效日期"
                      :disabled="!checkedChoices.includes('expiryDate')"
                      type="date"
                      :picker-options="pickerOptions1"
                      style="width: 200px"
                      value-format="yyyy-MM-dd"
                      v-model.trim="JZChoice.expiryDate"
                    ></el-date-picker>
                  </el-form-item>
                </el-checkbox>
                <el-checkbox label="productionBatchNumber" v-if="isMedichair">
                  <el-form-item prop="expiryDate" label-width="90px" label="生产批次号：">
                    <el-input
                      placeholder="请输入生产批次号"
                      :disabled="!checkedChoices.includes('productionBatchNumber')"
                      style="width: 200px"
                      v-model.trim="JZChoice.productionBatchNumber"
                    ></el-input>
                  </el-form-item>
                </el-checkbox>
                <el-checkbox label="productionSerialNumber" v-if="isMedichair">
                  <el-form-item prop="expiryDate" label-width="90px" label="生产序列号：">
                    <el-input
                      placeholder="请输入生产序列号"
                      :disabled="!checkedChoices.includes('productionSerialNumber')"
                      style="width: 200px"
                      v-model.trim="JZChoice.productionSerialNumber"
                    ></el-input>
                  </el-form-item>
                </el-checkbox>
              </el-checkbox-group>
            </el-form>
          </div>
          <div class="dialog-footer" style="text-align: right; display: inline-block; width: 100%">
            <el-button @click="isShow = false" size="mini">取 消</el-button>
            <el-button type="warning" @click="handleReset" size="mini">清 空</el-button>
            <el-button type="primary" @click="handleEnsure" size="mini">确 定</el-button>
          </div>
          <el-button
            icon="el-icon-plus"
            size="mini"
            type="primary"
            :disabled="!selectedLists.length"
            slot="reference"
            @click="isShow = !isShow"
          >
            批量录入
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
        </el-popover>
        <!-- <el-button icon="el-icon-plus" type="primary" :disabled='!selectedLists.length' size="small" @click="batchEntry">批量录入 <i class="el-icon-arrow-down el-icon--right"></i></el-button> -->
        <el-button
          icon="el-icon-close"
          size="mini"
          type="primary"
          color="#FF5500"
          :disabled="!selectedLists.length"
          style="margin-left: 10px"
          @click="batchEmptying"
        >
          批量清空
        </el-button>
        <el-button
          icon="el-icon-delete"
          size="mini"
          type="primary"
          :disabled="!selectedLists.length"
          @click="batchDelete"
        >
          批量删除
        </el-button>
      </div>
      <vxe-grid
        v-if="visible"
        class="content-block"
        resizable
        autoResize
        ref="xTable"
        border="full"
        size="small"
        max-height="500"
        row-id="rowId"
        :data="goodsSerialList"
        showOverflow
        highlight-current-row
        highlight-hover-row
        highlight-current-column
        highlight-hover-column
        align="center"
        @checkbox-change="handleCheckboxChanged"
        @checkbox-all="handleCheckboxAllChecked"
        :scroll-y="{ enabled: true, mode: 'default', gt: 30 }"
        :columns="childColumn"
        style="width: 100%"
        :edit-config="{ trigger: 'click', mode: 'cell' }"
        :edit-rules="setRules()"
      ></vxe-grid>
      <el-row style="margin-top: 12px; text-align: right">
        <el-button size="mini" @click="operateDeliveryGoods('cancel')">取消</el-button>
        <el-button type="primary" size="mini" @click="operateDeliveryGoods('save')">保存</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import setTableCols from './configs';
import { setDeliveryGoodsColumn } from './configs/shipConfig';
import { getSupplierPage, sellerDeliveAdd, deliver } from '@/api/pickUpOrders';
import * as Moment from 'dayjs';
import _ from 'lodash';

export default {
  name: 'ship',
  props: {
    id: {
      type: String,
    },
  },
  computed: {
    supId () {
      return this.$store.state.User.userInfo.supplierId;
    },
    allSelectedIdLists () {
      return this.selectedLists.map((item, index) => index);
    },
  },
  data () {
    const validateLogisticsObj = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('物流公司必填'));
      } else {
        callback();
      }
    };

    const validatelogisticsOrderCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('物流单号必填'));
      } else {
        callback();
      }
    };

    return {
      attchList: [],
      visible: false,
      info: {},
      dataList: [],
      isDirect: 1,
      columnList: setTableCols.call(this, 'ship'),
      logisticForm: {
        logisticsObj: '',
        logisticsOrderCode: '',
      },
      logisticRules: {
        logisticsObj: { validator: validateLogisticsObj, trigger: 'change' },
        logisticsOrderCode: {
          validator: validatelogisticsOrderCode,
          trigger: 'blur',
        },
      },
      logisticsOptions: [],
      childColumn: [],
      currentIndex: 0,
      goodsSerialList: [],
      selectedLists: [],
      isShow: false,
      checkedChoices: ['productionDate', 'expiryDate', 'productionBatchNumber', 'productionSerialNumber'],
      JZChoice: {
        productionBatchNumber: '',
        productionSerialNumber: '',
        expiryDate: '',
        productionDate: '',
      },
      isMedichair: true,
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      pickerOptions1: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() || time.getTime() > new Date(this.productionDate);
        },
      },
    };
  },
  created () {
    this.getDetail();
  },
  methods: {
    async handleEnsure () {
      // 判断选中项
      // 判断选中项有无值
      // 将选中的数据更新到选中的数据中
      // 将多选选中项移除
      // 关闭弹出框
      if (!this.checkedChoices.length) {
        this.$JZShowAlert('请先选择要批次录入项', 'error');
        return;
      }

      let currentInfo = {};
      this.checkedChoices.map((item) => {
        currentInfo[item] = this.JZChoice[item];
      });

      this.$confirmAlert(`确认将选中项录入到选中的${this.selectedLists.length}条商品信息吗?`, '确认录入').then(() => {
        let currentLists = this.goodsSerialList.map((item, index) => {
          if (this.allSelectedIdLists.includes(index)) {
            item = {
              ...item,
              ...currentInfo,
            };
          }
          return item;
        });
        this.goodsSerialList = currentLists;
        this.$refs.xTable.loadData(currentLists);
        this.selectedLists = [];
        this.$refs.xTable.clearCheckboxRow();
        this.$set(this.dataList[this.currentIndex], 'warn', false);
        this.dataList[this.currentIndex].consumeNum = this.goodsSerialList.length;
        this.dataList[this.currentIndex].goodsSerialList = this.goodsSerialList;
        this.isShow = false;
      });
    },
    batchEntry () {
      this.isShow = true;
    },
    handleReset () {
      let checkedChoices = this.checkedChoices;
      let JZChoice = this.JZChoice;
      checkedChoices.forEach((item) => {
        JZChoice[item] = '';
      });
      this.JZChoice = JZChoice;
    },
    batchEmptying () {
      // 将选中项数据数值初始化
      this.$confirmAlert(`确认将选中的${this.selectedLists.length}条商品信息清空吗?`, '确认清空').then(() => {
        let currentLists = this.goodsSerialList.map((item, index) => {
          if (this.allSelectedIdLists.includes(index)) {
            item = {
              ...item,
              productionBatchNumber: '',
              productionSerialNumber: '',
              expiryDate: '',
              productionDate: '',
            };
          }
          return item;
        });
        this.goodsSerialList = currentLists;
        this.$refs.xTable.loadData(currentLists);
        this.selectedLists = [];
        this.$refs.xTable.clearCheckboxRow();
        this.$set(this.dataList[this.currentIndex], 'warn', false);
        this.dataList[this.currentIndex].consumeNum = this.goodsSerialList.length;
        this.dataList[this.currentIndex].goodsSerialList = this.goodsSerialList;
        this.isShow = false;
      });
    },
    batchDelete () {
      // 将选中项中的数据从列表中移除
      // 将选中项数据数值初始化
      this.$confirmAlert(`确认将选中的${this.selectedLists.length}条数商品信息删除吗?`, '确认删除').then(() => {
        let currentLists
            = this.goodsSerialList.filter((item, index) => !this.allSelectedIdLists.includes(index)) || [];
        if (currentLists.length === 0) {
          this.$JZShowAlert('商品删除不可全部删除，至少需要有一条商品信息', 'warning');
          return;
        }
        this.goodsSerialList = currentLists;
        this.$refs.xTable.loadData(currentLists);
        this.selectedLists = [];
        this.$refs.xTable.clearCheckboxRow();
        this.$set(this.dataList[this.currentIndex], 'warn', false);
        this.dataList[this.currentIndex].consumeNum = this.goodsSerialList.length;
        this.dataList[this.currentIndex].goodsSerialList = this.goodsSerialList;
        this.isShow = false;
      });
    },
    handleCheckItemChange (value, key) {
      if (!value) {
        this.checkedChoices = this.checkedChoices.filter((item) => item !== key);
      }
    },
    handleCheckboxChanged ({ checked, row }) {
      this.$emit('select', { row, checked });
      this.handleSelectionChange();
    },
    handleCheckboxAllChecked ({ records, checked }) {
      this.$emit('select-all', { selections: records, checked });
      this.handleSelectionChange();
    },
    handleSelectionChange () {
      let selections = this.$refs.xTable.getCheckboxRecords();
      this.selectedLists = selections;
      // this.$emit('selection-change', selections);
    },
    validateProductionDate ({ cellValue: value, rowIndex }) {
      if (value && this.goodsSerialList[rowIndex].validateExpiryDate) {
        let s = new Date(value).getTime();
        let e = new Date(this.goodsSerialList[rowIndex].validateExpiryDate).getTime();

        if (s >= e) {
          return new Error('生产日期必须小于失效日期');
        }
      }
    },
    validateExpiryDate ({ cellValue: value, rowIndex }) {
      if (this.goodsSerialList[rowIndex].productionDate && value) {
        let s = new Date(this.goodsSerialList[rowIndex].productionDate).getTime();
        let e = new Date(value).getTime();

        if (s >= e) {
          return new Error('失效日期必须大于失生产日期');
        }
      }
    },
    validateNumber ({ rowIndex }) {
      if (
        !this.goodsSerialList[rowIndex].productionBatchNumber
          && !this.goodsSerialList[rowIndex].productionSerialNumber
      ) {
        return new Error('请填写生产批次号或者生产序列号');
      }
    },
    setRules () {
      const rules = {
        productionDate: [
          { required: true, message: '请选择生产日期' },
          { validator: this.validateProductionDate, trigger: blur },
        ],
        expiryDate: [
          { required: true, message: '请选择失效日期' },
          { validator: this.validateExpiryDate, trigger: blur },
        ],
        productionBatchNumber: [{ validator: this.validateNumber, trigger: blur }],
        productionSerialNumber: [{ validator: this.validateNumber, trigger: blur }],
      };

      if (this.isDirect === 2) {
        rules.consumeNum = [{ required: true, message: '请输入本次发货数量' }];
      }

      return rules;
    },
    setDate (type) {
      if (type === 'produce') {
        return Moment().add(-30, 'day').format('YYYY-MM-DD');
      } else {
        return Moment().add(2, 'year').format('YYYY-MM-DD');
      }
    },
    getDetail () {
      sellerDeliveAdd({ id: this.id }).then(({ body }) => {
        this.isDirect = body.isDirect;
        this.columnList = setTableCols.call(this, 'ship');
        body.detailAddress = `${body.receivingProvinceName || ''}${body.receivingCityName || ''}${
          body.receivingAreaName || ''
        }${body.receivingAddrs || ''}`;
        body.sellerDeliverGoodsRepDtoList.forEach((v) => {
          v.consumeNum = v.noShippedNum;
          v.goodsSerialList = [];

          for (let i = 0; i < v.consumeNum; i++) {
            let good = {
              goodsCode: v.goodsCode,
              goodsName: v.goodsName,
              brandName: v.brandName,
              specsNo: v.specsNo,
              producer: v.producer,
              deliveryNum: 1,
              isMedichair: v.isMedichair,
              regNo: v.regNo,
              productionDate: '',
              expiryDate: '',
              materialId: v.materialId,
              certificateNumber: v.regNo,
            };

            if (v.isMedichair === 2) {
              good.productionDate = this.setDate('produce');
              good.expiryDate = this.setDate();
            } else {
              good.productionBatchNumber = '';
              good.productionSerialNumber = '';
            }

            v.goodsSerialList.push(good);
          }
        });

        this.dataList = body.sellerDeliverGoodsRepDtoList;
        this.info = body;
      });
    },
    handleDeliveryGoods (v, index) {
      this.childColumn = setDeliveryGoodsColumn.call(this, v.isMedichair);
      this.currentIndex = index;
      this.goodsSerialList = _.cloneDeep(v.goodsSerialList);
      this.visible = true;
      // 初始化 批量操作信息
      let checkedChoices = ['productionDate', 'expiryDate', 'productionBatchNumber', 'productionSerialNumber'];
      let JZChoice = {
        productionBatchNumber: '',
        productionSerialNumber: '',
        expiryDate: '',
        productionDate: '',
      };
      this.isMedichair = v.isMedichair === 1;
      if (!this.isMedichair) {
        checkedChoices = ['productionDate', 'expiryDate'];
        JZChoice = {
          expiryDate: '',
          productionDate: '',
        };
      }
      this.checkedChoices = checkedChoices;
      this.JZChoice = JZChoice;
      this.selectedLists = [];
    },
    async operateDeliveryGoods (type, index) {
      if (type === 'add') {
        if (this.goodsSerialList.length >= this.dataList[this.currentIndex].noShippedNum) {
          this.$message({
            message: `待发数量为${this.dataList[this.currentIndex].noShippedNum}，发货物资数量最大为${
              this.dataList[this.currentIndex].noShippedNum
            }`,
            type: 'warning',
          });

          return;
        }

        let item = _.cloneDeep(this.goodsSerialList[0]);

        item.productionDate = this.goodsSerialList[0].isMedichair === 1 ? '' : this.setDate('produce');
        item.expiryDate = this.goodsSerialList[0].isMedichair === 1 ? '' : this.setDate();

        if (this.goodsSerialList[0].isMedichair === 1) {
          item.productionBatchNumber = '';
          item.productionSerialNumber = '';
        }

        item.rowId = Math.random();

        if (item.id) {
          delete item.id;
        }

        this.goodsSerialList.push(item);
      } else if (type === 'reduce') {
        this.goodsSerialList.splice(index, 1);
      } else if (type === 'cancel') {
        this.visible = false;
      } else if (type === 'save') {
        const $table = this.$refs.xTable;
        const errMap = await $table.validate(true).catch((errMap) => errMap);
        if (!errMap) {
          this.$set(this.dataList[this.currentIndex], 'warn', false);

          this.dataList[this.currentIndex].consumeNum = this.goodsSerialList.length;
          this.dataList[this.currentIndex].goodsSerialList = this.goodsSerialList;
          this.visible = false;
        }
      }
    },
    logisticsList (query = '') {
      getSupplierPage({
        status: 'EFFECTIVE',
        supplierType: 'CARRIER',
        supplierTypes: ['CARRIER'],
        pageSize: 10,
        nameOrNameEn: query,
        pageNum: 1,
      }).then(({ body }) => {
        this.logisticsOptions = body.list || [];
      });
    },

    cancel () {
      this.$router.back();
    },
    async save () {
      // if (this.dataList.length && !!this.dataList.find(i => i.isMedichair === 1) && !this.attchList.length) {
      //   return this.$message.error('发货单中含有医疗件，请上传随货同行单');
      // }
      let goNext = true;
      if (this.isDirect === 1) {
        this.dataList.forEach((v) => {
          v.goodsSerialList.forEach((i) => {
            this.$set(v, 'warn', false);

            if (!i.productionDate && !i.expiryDate) {
              v.warn = true;
            }

            if (v.isMedichair === 1 && !i.productionBatchNumber && !i.productionSerialNumber) {
              v.warn = true;
            }

            goNext &= !v.warn;
          });
        });
      } else if (this.isDirect === 2) {
        let total = this.dataList.reduce((cur, next) => {
          return cur + Number(next.consumeNum);
        }, 0);

        if (!total) {
          this.$message({
            message: '发货总量必须大于0',
            type: 'warning',
          });

          goNext = false;
        }
      }

      this.$refs.logisticForm.validate((valid) => {
        if (valid && goNext) {
          const internalSellerMaterIdCreateList = this.dataList.map((v) => {
            const item = {
              currentArrivalNum: v.consumeNum,
              orderCode: v.orderCode,
              materialId: v.materialId,
              saleOrderGoodsId: v.saleOrderGoodsId,
              orderItemId: v.id,
            };

            item.internalSellerMaterIdSeralList = [...v.goodsSerialList];

            return item;
          });
          const accompanyBilList = this.attchList.map(i => ({
            annexName: i.attachName,
            annexUrl: i.url,
          }));

          const param = {
            logistics: this.logisticForm.logisticsObj.name,
            logisticsCode: this.logisticForm.logisticsObj.code,
            logisticsId: this.logisticForm.logisticsObj.id,
            oddNumbers: this.logisticForm.logisticsOrderCode,
            orderCode: this.info.orderCode,
            orderId: this.info.orderId,
            planArrivalWarehouse: this.info.arrivalWarehouse,
            supId: this.supId,
            warehouseCode: this.info.warehouseCode,
            warehouseId: this.info.warehouseId,
            internalSellerMaterIdCreateList,
            accompanyBilList,
          };

          deliver(param).then(() => {
            this.$message({
              type: 'success',
              message: '发货成功!',
            });

            this.$router.back();
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .ship {
    .el-table {
      margin-bottom: 1em;
    }

    .common-font {
      color: #909399;
      font-size: 14px;
      font-weight: 500;
    }

    .gap-bottom {
      margin-bottom: 1em;
    }

    /deep/ .contract-option {
      color: #02a7f0;
      cursor: pointer;
      padding: 0 0.25em;
    }

    /deep/ .num {
      color: blue;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &.warn {
        color: red;

        &::after {
          content: '请完善发货商品信息';
          position: absolute;
          bottom: -2px;
          font-size: 12px;
          left: 0;
          transform: translateX(-50%) scale(0.9);
          display: inline-block;
          left: 50%;
        }
      }
    }

    .numInput {
      /deep/ .el-input__inner {
        border-width: 0;
        text-align: center;
        color: blue;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  /deep/ .el-checkbox .el-checkbox__input {
    margin-top: 13px;
  }
  .headerTitle {
    display: flex;
    span {
      flex: 1;
      line-height: 24px;
      font-size: 18px;
      color: #303133;
    }
    i {
      color: #409eff;
      margin-right: 12px;
    }
  }
  /deep/ .tooltipContent {
    padding: 12px;
    h4 {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 16px;
      padding: 0 12px;
      margin-bottom: 10px;
    }
    ol {
      margin: 12px;
      li {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
        padding: 0 12px;
      }
    }
  }
</style>
